import { useState, useCallback } from 'react';

import { IPatient } from '../types/models/Patient';
import { ApiClient } from '../services/api/ApiClient';


export const usePatients = () => {
  const [patients, setPatients] = useState<IPatient[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchPatients = useCallback(async (params?: any) => {
    setLoading(true);
    setError(null);
    try {
      const data = await ApiClient.patients.index(params);
      setPatients(data);
    } catch (err) {
      setError('Failed to fetch patients');
    } finally {
      setLoading(false);
    }
  }, []);

  const deletePatient = useCallback(async (id: string | number) => {
    setLoading(true);
    setError(null);
    try {
      await ApiClient.patients.delete(id);
      setPatients((prev) => prev.filter((patient) => patient.id !== id));
    } catch (err) {
      setError('Failed to delete patient');
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    patients,
    loading,
    error,
    fetchPatients,
    deletePatient,
  };
};